import Typography from "typography"
import Sutro from "typography-theme-sutro"

Sutro.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    // Indent Markdown bullet lists
    "article ul li": {
    	'margin-left': '4em',
    }
  }
}

delete Sutro.googleFonts

const typography = new Typography(Sutro)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
